import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { message, notification, Button, Modal, Spin } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.use(Button);
Vue.use(Modal);
Vue.use(Spin);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
