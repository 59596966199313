<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  }
}
</script>

<style lang="less">
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
dl,
p {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
li {
  list-style: none;
}
a {
  color: #333;
}
.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  .flex;
  flex-direction: column;
}
</style>
