import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes =[
  // {
  //   path: '/',
  //   name: '登录',
  //   component: () => import('@/login'),
  // },
  {
    path: '/',
    name: '孔子博物馆馆长数据中台',
    component: () => import('@/index'),
    meta: {
      needToken: true,
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]
const router = new VueRouter({
     routes,
})
export default router


